.top-bar-menu-container {
  background-color: #fff;
  // position: absolute;
  top: 0;
  left: 200px;
  right: 0;
  height: 100px;
  display: flex;
  z-index: 8;
}

.total_debt_container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  position: relative;

  & .total_debt_info_container {
    position: absolute;
    min-width: 220px;
    top: 90%;
    left: -10px;
    padding: 5px 7px;
    background-color: white;
    box-shadow: 0px -1px 5px rgba($color: black, $alpha: .15);
    border-radius: 3px;
    transform: scale(0);
    transform-origin: 20px top;
    transition: all .15s ease;
    z-index: 10;

    &.active {
      transform: scale(1);
    }

    & .debt_title {
      margin: 2px 7px 5px 7px;
      user-select: none;
    }

    &::before {
      content: '';
      width: 12px;
      height: 12px;
      background-color: white;
      position: absolute;
      top: -5px;
      left: 16px;
      border-radius: 4px;
      transform: rotate(45deg) skew(12deg, 12deg);
      box-shadow: -1px -1px 0px rgba($color: black, $alpha: .08);
      overflow: hidden;
    }

    & span {
      font-size: 13px;
    }

    & .table_grid {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: 2px;
      row-gap: 2px;

      & > span {
        background-color: #e3dfe1;
        padding: 7px 15px;
        color: #464749;
        border-radius: 2px;

        &.active {
          background: linear-gradient(to right, #f85f0b, #f28648);
          color: white;
        }

        &:nth-child(even){
          text-align: end;
        }
      }
    }

    & .card_container {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin: 10px 0px;

      & .btn {
        background: linear-gradient(to top, #e5e5e6, white);
        border: 1px solid #e5e5e6;
        border-radius: 5px;
        padding: 5px 25px 7px 25px;
        color:#f85f0b;
      }
    }
  }
}

.top-bar-menu-item-box {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0 40px;
  text-transform: uppercase;
  color: #9f9f9f;
  font-size: 14px;
  font-weight: 600;

  a:hover {
    text-decoration: none !important;
  }

  &:hover {
    color: darken($wht, 50%);
    background-color: #ffffff;

    -webkit-transition: background-color 500ms ease-in;
    -ms-transition: background-color 500ms ease-in;
    transition: background-color 500ms ease-in;
  }
}

.top-search-bar-container {
  background-color: #e2e0e1;
  position: absolute;
  top: 100px;
  left: 200px;
  right: 0;
  height: 60px;
  display: flex;
  z-index: 7;
}

.top-bar-menu-item-box:hover {
  background-color: #e2e0e1;
  cursor: auto;
}

.sm-paddings {
  padding: 0px 10px !important;
}

.top-bar-menu-item-box.orange {
  color: #fb5700;
  padding: 0 30px;

  .ant-checkbox {
    margin-right: 10px;

    .ant-checkbox-inner {
      width: 24px;
      height: 24px;
    }
  }

  // .ant-checkbox:after {
  //   border-color: #9f9f9f !important;
  // }
  .ant-checkbox-checked:after {
    border-color: #9f9f9f !important;
  }

  .ant-checkbox-checked {
    .ant-checkbox-inner {
      background: white !important;
    }

    .ant-checkbox-inner:after {
      border-color: #9f9f9f !important;
      left: 7px !important;
    }
  }
}

.top-bar-menu-user {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 40px;
  text-transform: uppercase;
  color: #9f9f9f !important;
  font-size: 14px;
  font-weight: 600;

  .ri-user-fill {
    margin: 0 10px 0 0;
    font-size: 22px;
  }
}

.additional_discount_container {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
  border-right: 20px solid transparent;
  position: relative;

  &::after {
    content: '';
    width: 20px;
    height: 100%;
    position: absolute;
    left: 100%;
    background-color: #fb5700;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%, 100% 50%, 0 0);
  }

  & .info {
    color: black;
  }

  & .orange {
    color: #fb5700;
  }
}

.additional_discount_sum_container {
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(to right, #fb5700, #f5864d);
  padding-left: 10px;
  gap: 7px;
  border-right: 20px solid #f5864d;
  position: relative;
  margin-left: -1px;

  &::after {
    content: '';
    width: 20px;
    height: 100%;
    position: absolute;
    left: 100%;
    background-color: #e2e0e1;
    clip-path: polygon(0 0, 102% 0, 102% 100%, 0% 100%, 102% 50%, 0 0);
  }
  
  & p{
    font-weight: 500;
    color: white;
  }

    & .total_debt_info_container {
      position: absolute;
      top: 100%;
      left: -10px;
      padding: 5px 7px 7px 7px;
      background-color: white;
      box-shadow: 0px -1px 5px rgba($color: black, $alpha: .15);
      border-radius: 3px;
      transform: scale(0);
      transform-origin: 20px top;
      transition: all .15s ease;
      z-index: 10;

      @media (max-width:1300px) {
        right: 0;
        left: unset;
      }
  
      &.active {
        transform: scale(1);
      }
  
      & .debt_title {
        margin: 2px 7px 5px 7px;
        user-select: none;
      }
  
      &::before {
        content: '';
        width: 12px;
        height: 12px;
        background-color: white;
        position: absolute;
        top: -5px;
        left: 16px;
        transform: rotate(45deg) skew(12deg, 12deg);
        box-shadow: -1px -1px 0px rgba($color: black, $alpha: .08);
        overflow: hidden;

        @media (max-width:1300px) {
          right: 16px;
          left: unset;
        }
      }
  
      & span {
        font-size: 13px;
      }
  
      & .table_grid {
        display: grid;
        grid-template-columns: 180px 1fr;
        column-gap: 2px;
        row-gap: 2px;
  
        &>span {
          background-color: #e3dfe1;
          padding: 7px 15px;
          color: #464749;
          border-radius: 2px;
  
          &.active {
            background: linear-gradient(to right, #f85f0b, #f28648);
            color: white;
          }
  
          &:nth-child(even) {
            text-align: left;
            padding: 7px;
          }
        }
      }
    }
}