main.ant-layout-content {
  section.ant-layout.ant-layout-has-sider.site-layout-background {
    height: 100%;
  }
}

.ant-menu-inline .ant-menu-submenu-title {
  margin-top: 0px;
  margin-bottom: 0px;
}

.ant-layout-sider {
  .logo {
    text-align: center;
    background-color: #f84804;

    img {
      max-height: 100% !important;
      max-width: 100% !important;
      width: 100%;
      height: 100px;
    }

    Button {
      margin-top: 15px;
    }
  }

  .ant-menu-submenu:hover {
    background-color: #707070;
  }

  .ant-menu-submenu-title {
    padding: 30px 0;
    // padding-left: 34px !important;
    padding-right: 34px;
    text-align: left;

    .ant-menu-title-content {
      font-size: 14px;
      font-weight: 600;
      color: #9f9f9f;
      text-transform: uppercase;
    }

    // .ant-menu-title-content:hover {
    //   background-color: lighten($wht, 30%);
    // }

    span {
      i {
        vertical-align: inherit;
        position: relative;
        top: -1px;
      }
    }

    .ant-menu-submenu-arrow {
      transform: translateY(2px);
    }
  }

  .ant-menu-submenu-open {
    .ant-menu-submenu-title {
      .ant-menu-submenu-arrow {
        transform: translateY(0);
      }
    }
  }

  .ant-menu-item {
    margin-top: 0;
    margin-bottom: 0;

    &:first-child {
      margin-top: 0px;
    }

    &:last-child {
      margin-bottom: 0px;
    }

    a,
    a:visited {
      &:hover {
        text-decoration: none;
      }
    }
  }

  .ant-menu-sub.ant-menu-inline>.ant-menu-item {
    height: 35px;
  }

  .ant-menu-inline .ant-menu-item:not(:last-child) {
    margin-bottom: 0;
  }
}

.ant-menu-vertical .ant-menu-item:not(:last-child),
.ant-menu-vertical-left .ant-menu-item:not(:last-child),
.ant-menu-vertical-right .ant-menu-item:not(:last-child),
.ant-menu-inline .ant-menu-item,
.ant-menu-inline .ant-menu-item-only-child {
  margin-bottom: 0;
  margin-top: 0;
}

.ant-layout-sider .ant-menu-item:last-child {
  margin-bottom: 0;
}

span.anticon {
  vertical-align: 0.15em;
}

// main.ant-layout-content {
//   padding: 0 0 0 15px !important;
// }

@media only screen and (max-width: 992px) {
  .ant-layout-sider {
    .logo {
      border: none;
      padding: 0px 3px !important;
    }
  }

  main.ant-layout-content section.ant-layout.ant-layout-has-sider.site-layout-background {
    display: flex;
    flex-direction: column;
  }

  .ant-layout.ant-layout-has-sider>.ant-layout-content {
    width: 100%;
    padding: 0px !important;
  }
}

// .ant-menu-submenu-open {
//   .ant-menu-submenu-title {
//     background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(0,0,0,0) 3%, rgba(0,0,0,0) 100%);
//     margin: 0;
//   }
//   .ant-menu-submenu-title:hover {
//     background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(0,0,0,0) 3%, rgba(0,0,0,0) 100%);
//   }
// }

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background: transparent;
}

// .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected:before {
//   content: '';
//   width: 5px;
//   height: 25px;
//   background: $wht;
//   border-radius: 0 6px 6px 0;
//   position: absolute;
//   left: 0;
//   top: 5px;
// }

.ant-menu-inline .ant-menu-submenu,
.ant-menu-vertical-left .ant-menu-submenu,
.ant-menu-vertical-right .ant-menu-submenu,
.ant-menu-vertical .ant-menu-submenu {
  font-weight: 400;
  border-bottom: 0.5px solid #525252;
}

.sider-profile-controls {
  box-sizing: border-box;
  width: 100%;
  // position: absolute;
  // bottom: 40px;
  //height: 40px;
  line-height: 40px;
  padding: 0 34px;
  color: $wht;
  margin: 20px 0;

  .sider-profile-controls-profile,
  .sider-profile-controls-logout {
    font-size: 22px;
  }

  .sider-profile-controls-profile:hover,
  .sider-profile-controls-logout:hover,
  .sider-profile-controls-language:hover {
    color: darken($wht, 30%);
  }

  .sider-profile-controls-profile {
    margin-right: 20px;
  }

  .sider-profile-controls-language {
    padding: 0;
    margin: 0;
    color: $k100;
  }
}

.nav-display {
  .ant-layout-sider-children {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .ant-menu {
      flex-grow: 2;
    }
  }
}

// ukidanje scroll-ova * {
//   scrollbar-width: none !important; /* Firefox i Chrome ukidanje svih scrollbar-a*/
// }

// .ant-layout-sider-children {
//   scrollbar-width: none; /* Firefox i Chrome ukidanje svih scrollbar-a*/
// }

* {
  -ms-overflow-style: none;
}

// ::-webkit-scrollbar {
//   display: none;
// }

.newLayout {
  display: none;
}

@media screen and (min-width:500px) and (max-width:1240px) {
  .oldLayout {
    display: none !important;
  }

  .ant-layout-sider-below {
    display: flex;
  }

  .newLayout {
    display: flex;
  }

  .ant-layout-sider {
    width: fit-content !important;
    padding: 0;
    height: auto;

  }

  .ant-layout-sider-dark {
    position: relative !important;
    overflow: unset !important;
  }

  .ant-layout-sider-trigger {
    display: none;
  }

  .ant-layout-sider-children {
    position: relative;
    overflow: unset !important;

  }

  .openSideBar {
    position: absolute;
    top: 30px;
    right: -15px;
    width: 40px !important;
    height: 40px !important;
    border-radius: 40% !important;
    background-color: #373737 !important;

    &:focus {
      background-color: #373737 !important;
      opacity: 1 !important;
    }
  }

  .ant-layout-content {
    margin: 0px 30px !important;
  }

  .ant-menu.ant-menu-inline-collapsed>.ant-menu-item,
  .ant-menu.ant-menu-inline-collapsed>.ant-menu-item-group>.ant-menu-item-group-list>.ant-menu-item,
  .ant-menu.ant-menu-inline-collapsed>.ant-menu-item-group>.ant-menu-item-group-list>.ant-menu-submenu>.ant-menu-submenu-title,
  .ant-menu.ant-menu-inline-collapsed>.ant-menu-submenu>.ant-menu-submenu-title {
    padding: 0 !important;
    text-align: center;
  }

  .ant-menu.ant-menu-inline-collapsed>.ant-menu-item .ant-menu-item-icon+span,
  .ant-menu.ant-menu-inline-collapsed>.ant-menu-item-group>.ant-menu-item-group-list>.ant-menu-item .ant-menu-item-icon+span,
  .ant-menu.ant-menu-inline-collapsed>.ant-menu-item-group>.ant-menu-item-group-list>.ant-menu-submenu>.ant-menu-submenu-title .ant-menu-item-icon+span,
  .ant-menu.ant-menu-inline-collapsed>.ant-menu-submenu>.ant-menu-submenu-title .ant-menu-item-icon+span,
  .ant-menu.ant-menu-inline-collapsed>.ant-menu-item .anticon+span,
  .ant-menu.ant-menu-inline-collapsed>.ant-menu-item-group>.ant-menu-item-group-list>.ant-menu-item .anticon+span,
  .ant-menu.ant-menu-inline-collapsed>.ant-menu-item-group>.ant-menu-item-group-list>.ant-menu-submenu>.ant-menu-submenu-title .anticon+span,
  .ant-menu.ant-menu-inline-collapsed>.ant-menu-submenu>.ant-menu-submenu-title .anticon+span {
    display: none;
  }


  .ant-layout-sider-collapsed .ant-menu-submenu-arrow {
    opacity: 0 !important;
  }

  .ant-menu-dark .ant-menu-sub,
  .ant-menu.ant-menu-dark .ant-menu-sub {
    background-color: #373737;
    border-bottom: .5px solid gray;
  }

  .ant-menu-inline .ant-menu-submenu,
  .ant-menu-vertical-left .ant-menu-submenu,
  .ant-menu-vertical-right .ant-menu-submenu,
  .ant-menu-vertical .ant-menu-submenu {
    padding: 8px;
  }

  .sider-profile-controls {
    padding: 0 28px !important;
    // border-bottom: .5px solid gray;
  }

  .ant-menu-submenu-arrow {
    opacity: 1 !important;
  }

  .ant-menu-inline>.ant-menu-submenu>.ant-menu-submenu-title {
    height: auto !important;
    background-color: darken($color: gray, $amount: 20);
    border-radius: 4px;
    border: 1px solid gray;
    padding: 0 6px !important;
  }

  .ant-menu-title-content {
    margin: 0px !important;
  }

  .ant-menu-item .ant-menu-item-only-child {
    padding: 0px !important;
  }

  .iconLabelContainer {
    position: unset !important;
  }

  .ant-menu-item {
    padding-left: 15px !important;
  }

  .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected:before {
    content: '';
    width: 5px;
    height: 5px;
    background-color: white;
    position: absolute;
    border-radius: 50%;
    top: 14px;
    left: 4px;
  }

  .ant-menu-item-active,
  .ant-menu-item-selected {
    background-color: darken($color: gray, $amount: 20) !important;
    border-radius: 4px;
    border: 1px solid gray;
  }

  .ant-menu-item,
  .ant-menu-item-only-child {
    margin: 12px 0 !important;
  }

  .ant-breadcrumb>ol {
    display: flex;
    align-items: center;
  }

  .rabatLine {
    height: 2px !important;
    // top: 39px !important;
  }


  .top-bar-menu-container {
    position: unset !important;
    z-index: unset !important;
  }

  .top-search-bar-container {
    position: unset !important;
    margin-bottom: 10px !important;
  }

  .ant-menu-dark.ant-menu-inline .ant-menu-item {
    gap: 4px;
  }

  .ant-table-wrapper {
    margin-right: 45px;
  }

  .spanRabat {
    padding: 7.5px !important;
    margin-top: 0px !important;
    font-size: 10px !important;
  }

  .ant-input {
    // width: 400px !important;

    &::placeholder {
      font-size: 12px !important;
    }
  }

  .top-bar-menu-item-box,
  .orange {
    padding: 16px !important;
  }

  @media (max-width:869px) {

    .top-search-bar-container {
      justify-content: center;
      gap: 10px;
    }

    .top-bar-menu-item-box,
    .orange {
      padding: 10px !important;
      font-size: 12px !important;
    }

    .ant-input {
      max-width: 250px !important;

      &::placeholder {
        font-size: 10px !important;
      }
    }

    .ri-search-line {
      right: 20px !important;
    }
  }

  .ant-table-cell>span {
    // cursor: pointer;
    // white-space: nowrap;
    // overflow: hidden;
    // text-overflow: ellipsis;
    // max-width: 200px;
    // display: inline-block
  }

  .ant-table-thead {
    line-height: 9px !important;
  }

}

.ant-table-thead {
  line-height: 9px !important;
}

@media only screen and (min-width: 768px) and (max-width: 979px) {
  .flex-wrapper {
    flex-wrap: wrap;
  }

  .rabatInput {
    width: 300px !important;
  }

  .rabat-wrapper {
    width: 100% !important;
  }
}