.ant-modal {
  top: 40%;
  left: 40%;
}

.ant-modal-close {
  color: lighten($color: red, $amount: 20);

  &:hover {
    color: red;
  }

  &:active {
    color: red;
  }

  &:focus {
    color: red;
  }
}

.ant-modal-close-x {
  font-size: 30px;
  width: 65px;
  height: 65px;
  line-height: 65px;
}

.ant-modal-content {
  width: 450px;
  height: 250px;
  display: flex;
  justify-content: center;
  background-color: lighten($color: gray, $amount: 40);
  border-radius: 6px;
}

.ant-modal-body {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.outlet-title {
  font-size: 30px;
  color: red;
  font-weight: 700;
}

.outlet-line {
  position: relative;
  /* Required for positioning the pseudo-element */
  padding: 1.6px 100px;
  /* Adjust padding as needed */
  background: linear-gradient(to right, lightgray, rgb(183, 183, 183), rgb(160, 160, 160), rgb(183, 183, 183), lightgray);
  /* Background color for the pointy background */
  color: #fff;
}

.outlet-line::before {
  content: '';
  /* Required for pseudo-element */
  position: absolute;
  top: 0;
  left: -49px;
  /* Adjust the negative value to control the pointiness */
  border-width: 2px 50px 2px 0;
  /* Adjust the border width to control the pointiness */
  border-style: solid;
  border-color: transparent lightgray transparent transparent;
}

.outlet-line::after {
  content: '';
  /* Required for pseudo-element */
  position: absolute;
  top: 0;
  right: -49px;
  /* Adjust the negative value to control the pointiness */
  border-width: 2px 50px 2px 0;
  /* Adjust the border width to control the pointiness */
  border-style: solid;
  border-color: transparent lightgray transparent transparent;
  transform: rotate(180deg);
}


.outlet-desc {
  width: 250px;
  letter-spacing: .5px;
  line-height: 26px;
  font-size: 18px;
  color: gray;
  text-align: center;
  margin-top: 18px;
}

@media screen and (min-width:500px) and (max-width:1240px) {
  .ant-modal {
    top: 30%;
    left: 30%;
  }
}