.home_container {
  height: 100%;
  padding: 0px 20px 20px 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  & .banners_with_debt_value_container {
    display: flex;
    gap: 50px;

    & .banners_container {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      column-gap: 15px;
      row-gap: 15px;

      & .banner {
        display: flex;
        flex-direction: column;
        text-decoration: none;
        max-width: 270px;

        & .banner_image {
          border-top-left-radius: 6px;
          border-top-right-radius: 6px;
          overflow: hidden;
          height: 350px;

          & .img {
            height: 100%;
            object-fit: cover;
            object-position: center;
          }
        }

        & .banner_title {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 10px;
          padding: 10px;
          border: 1px solid #d4d4d4;
          border-top: none;

          & .title {
            color: black;
          }

          & .arrow_icon {
            border: 1px solid #d4d4d4;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 2px;
            cursor: pointer;

            & .icon {
              font-size: 20px;
              margin-left: 2px;
            }
          }
        }
      }
    }

    & .debt_container {
      width: 200px;
      display: flex;
      flex-direction: column;
      flex-shrink: 0;
      gap: 15px;

      & .total_debt_container,
      & .debt_out_of_value_container,
      & .konto_container {
        display: flex;
        flex-direction: column;
        gap: 10px;
        justify-content: space-between;

        & .total_debt_header,
        & .debt_out_of_value_header,
        & .konto_container_header {
          display: flex;
          align-items: center;
          gap: 6px;

          & .total_debt_title,
          & .debt_out_of_value_title,
          & .konto_container_title {
            color: #fb5700;
            font-weight: 500;
            text-transform: uppercase;
            font-size: 14px;
          }
        }

        & .total_debt_value,
        & .debt_out_of_value_price {
          border: 1px solid black;
          padding: 7px;
          display: flex;
          align-items: center;
          justify-content: center;

          & span {
            font-weight: bold;
            color: black;
            font-size: 22px;
          }
        }

        & .debt_out_of_value_price {
          background-color: red;

          & span {
            color: white;
          }
        }
      }

      & .debt_message_container {
        flex: 1;

        & p {
          color: black;
          font-weight: 500;
          text-align: justify;
          font-size: 12px;
        }
      }

      & .debt_request_container {

        & .request_btn {
          background: linear-gradient(to bottom, orange 40%, #fb5700 100%);
          color: white;
          font-weight: 600;
          text-transform: uppercase;
          width: 100%;
          padding: 10px;
          border: 1px solid gray;
          border-radius: 6px;

          &:disabled {
            background: lightgray;
            color: gray;
            opacity: .5;
            cursor: default;
          }
        }
      }
    }
  }

  & .catalogs_container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 15px;
    row-gap: 15px;

    & .catalog {
      display: flex;
      flex-direction: column;
      gap: 10px;
      text-decoration: none;
      max-width: 270px;

      & .catalog_image {
        aspect-ratio: 1/1;

        & .img {
          object-fit: cover;
          object-position: center;
          height: 100%;
        }
      }

      & .catalog_title {
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 102px;

        & .title {
          display: flex;
          color: black;
          text-transform: uppercase;
          text-align: center;
        }
      }
    }
  }
}