.ant-row .ant-col.ant-col-14 {
  font-size: 14px;
  font-weight: 500;
}

.ant-row .ant-col.ant-col-10 {
  font-size: 14px;
  font-weight: 500;
}

.ant-row.bolded {
  margin-bottom: 15px;
}

.ant-row.bolded>div {
  font-weight: 700 !important;
  color: black;
}

textarea:hover,
textarea:active,
textarea:visited,
textarea:focus {
  border-color: #ff7040 !important;
  outline: none;
}

.ant-select-selector:hover,
.ant-select-selector:focus-within {
  border: 1px solid #ff7040 !important;
}

.ant-select-selector {
  box-shadow: unset !important;
}

.advance_payment_checkbox {
  >label>span {
    color: #ff7040;
    font-weight: 700;
  }

  margin-top: 18px;

}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #ff7040 !important;
  border-color: #ff7040 !important;

  :hover {
    border-color: #ff7040 !important;
  }
}

.ant-checkbox .ant-checkbox-inner {
  // background-color: #ff7040 !important;
  border-color: #ff7040 !important;

  :hover {
    border-color: #ff7040 !important;
  }
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus+.ant-checkbox-inner {
  border-color: #ff7040;
}

.ant-checkbox-checked::after {
  border: 1px solid #ff7040;
}