footer {
  background-color: #272727;
  // margin-top: 5px;
  height: 'fitContent';
  width: '100%';
  position: 'absolute';
  bottom: 0;
  z-index: 7;

  * {
    font-family: 'Montserrat', sans-serif !important;
  }

  .container {
    min-height: '220px';
    height: 'fitContent';

    width: '100%';
  }

  .column {
    flex: initial;
    padding: 0;
  }

  .footer-wrapper {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    // padding-top: 20px;
    // padding-bottom: 10px;
    flex-wrap: wrap;
    padding: 20px 12% 10px 230px;

    .column-newsletter,
    .column-logo,
    .column-info,
    .column-docs,
    .column-contact {
      @include media('<=laptop') {
        @include media('>sm') {
          width: 26%;
          margin-bottom: 20px;
        }
      }
    }

    // .column-logo img {
    //   margin-bottom: 20px !important;
    // }

    .column-newsletter {
      @include media('<=laptop') {
        @include media('>sm') {
          width: 63%;
        }
      }
    }

    @include media('<=sm') {
      flex-direction: column;
      // padding-top: 40px;
      padding-bottom: 40px;
      align-items: center;
      text-align: center;

      .column {
        margin-bottom: 20px;
        width: 100%;
      }
    }
  }

  .nav-first-column-footer li:first-child {
    margin-bottom: 9px;
    font-size: 12px;
    color: #999999;
    font-weight: normal;
    margin-top: 20px;
  }

  nav {
    ul {
      li {
        margin-bottom: 6px;
        font-size: 12px;
        color: #999999;

        a {
          color: #999999 !important;

          &:hover {
            color: #fff !important;
          }
        }

        &:first-child {
          font-size: 14px;
          font-weight: bold;
          color: #fff;
          margin-bottom: 15px;

          @include media('<=sm') {
            margin-bottom: 15px;
          }
        }
      }
    }

    .mailing-list {
      max-width: 264px;

      @include media('<=sm') {
        width: 100%;
        max-width: 100%;
      }
    }

    .newsletter-title {
      color: #f95e00 !important;
    }

    .newsletter {
      margin-top: 15px;

      @include media('<=sm') {
        margin-top: 35px;
      }

      form {
        display: flex;

        button {
          height: 44px;
          margin-left: 0;
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
          font-size: 15px;
          font-weight: 700;
          white-space: nowrap;
        }

        input {
          height: 44px;
          background-color: #2b2b2b;
          color: #999999;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
      }
    }
  }

  .horizontal-divider {
    width: 100%;
    height: 2px;
    background-color: #313131;
  }

  .footer-copyright {
    padding-top: 30px;
    padding-bottom: 30px;
    font-size: 0.7em;
  }

  @include media('<=lg') {
    .footer-copyright {
      text-align: center;
      padding-top: 30px;
      padding-bottom: 30px;
    }
  }
}

.footer-social-media {
  display: flex;
  width: 108px;
  justify-content: space-between;
  align-items: center;
  margin-top: 21px;

  @include media('<=sm') {
    margin: 0 auto;
    margin-top: 25px;
  }

  a:hover,
  a:active {
    opacity: 0.5;
  }

  img {
    width: 20px;

    &:first-child {
      height: 22px;
    }
  }
}

.address-footer {
  justify-content: center;
  @include media('>laptop') {
    position: relative;
    right: 20px;
  }
}

.phone-footer {
  margin-top: 15px;
  a {
    justify-content: center;
  }

  @include media('>laptop') {
    position: relative;
    right: 20px;
  }
}

.newsletter-button {
  height: 30px !important;
  margin-left: 0 !important;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  font-size: 15px;
  font-weight: 700;
  white-space: nowrap;
}

.newsletter-input {
  height: 30px !important;
  background-color: #2b2b2b !important;
  color: #999999 !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-color: #333333 !important;
}

.newsletter-status {
  margin-top: 10px;
  word-wrap: break-word;
  width: 300px;

  @include media('<=635px') {
    width: 260px;
  }

  @include media('<=sm') {
    width: 100%;
  }
}

.footer-bottom {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  padding-inline: 12%;

  @include media('<=930px') {
    flex-direction: column;
    align-items: center;
  }
}

.all-secure-icon {
  position: relative;
  top: 3px;
}

.visa-icon,
.master-icon {
  position: relative;
  top: 2px;
}

.cards-wrapper {
  display: flex;
  align-items: flex-end;

  @include media('<=930px') {
    padding-bottom: 30px;
  }
}

footer .column-info nav ul li,
footer .column-docs nav ul li {
  margin-bottom: 9.5px;

  &:first-child {
    margin-bottom: 15px;
  }
}

.footer-sticky {
  display: none;
}

.footer-logo {
  margin-bottom: 0 !important;
  aspect-ratio: 2.64 / 1;
}

@media (max-width: 1088px) {
  .address-footer,
  .phone-footer a {
    justify-content: flex-start !important;
  }
}

@media (max-width: 576px) {
  .footer-sticky {
    position: sticky;
    background-color: #272727;
    display: flex;
    justify-content: center;
    padding-top: 20px;
    bottom: 0;
    z-index: 1004;
  }

  .address-footer,
  .phone-footer a {
    justify-content: center !important;
  }

  // .footer-logo {
  //   display: none !important;
  // }

  .footer-ref {
    scroll-margin: 100px;
    height: 0;
    margin-top: -5px;
  }
}

@media (min-width: 577px) {
  .address-footer {
    justify-content: center;
  }

  .phone-footer {
    a {
      justify-content: center;
    }
  }
}
