.ant-pagination {
  &-prev {
    .ant-pagination-item-link {
      color: #3700ec;
      //   margin-right: 20px;
      &:hover {
        color: #3700ec;
      }
    }
  }
  &-next {
    color: #3700ec;
    .ant-pagination-item-link {
      color: #3700ec;
      &:hover {
        color: #3700ec;
      }
    }
  }
  &.mini {
    .ant-pagination-item {
      min-width: 26px;
    }
  }
  &-item {
    a {
      &:hover {
        color: #000;
      }
    }
  }
  &-item-active {
    border-color: #7999bc;
    color: #fff;
    background-color: #7999bc;
    button {
      &.ant-pagination-item-link {
        color: #7999bc;
      }
    }

    &:hover {
      border-color: darken($c-primary, 5);

      a,
      a:visited {
        color: darken($c-primary, 5);
      }
    }

    a,
    a:visited {
      color: #fff;

      &:hover,
      &:active {
        text-decoration: none;
        color: #fff;
      }
    }
  }

  // &-options-size-changer {
  //     &.ant-select {
  //         margin-right: 0;
  //     }
  // }

  li {
    vertical-align: bottom;
  }

  .anticon {
    vertical-align: middle;
  }
}

.ant-table-pagination {
  &.ant-pagination {
    ul {
      li {
        color: #3370ec !important;
      }
    }
    &:last-child {
      margin-bottom: 12px;
    }
  }
}

.anticon.anticon-left {
  color: #7999bc;
  &:hover {
    color: #fff;
  }
}

.anticon.anticon-right {
  color: #7999bc;
  &:hover {
    color: #fff;
  }
}

.ant-pagination-item a:hover {
  text-decoration: none;
  color: #fff;
}

.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon,
.ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
  color: #fff;
}
