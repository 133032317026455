.orderHeader {
  display: flex;
  align-items: center;
  background-color: white;
  padding: 8px;
  margin-bottom: 15px;
  gap: 4px;
  position: sticky;
  top: 2px;
  left: 0;
  z-index: 66;
}

.orderHeaderSpan {
  margin-bottom: 3px;
  font-weight: 700;
  color: #4d4d4d;
  font-size: 16px;
}

.orderCol {
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-bottom: 60px;
}

.singleOrder {

  .ant-table-thead>tr>th {
    text-align: left !important;
    background-color: #4d4d4d;
    color: white !important;
    padding: 5px 8px 5px 16px !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    font-family: sans-serif;
    border: 1px solid white !important;
  }

  .ant-table-column-title {
    color: white !important;
  }

  .ant-table-tbody>tr {
    >td {
      text-align: left !important;
      padding: 8px 15px !important;
      font-size: 12px !important;
      font-family: sans-serif;

    }

  }

  .ant-table-tbody>tr:nth-child(2n+1) {
    background-color: #ebebeb !important;
  }

  .ant-table-cell-row-hover {
    background-color: unset !important;
  }

  margin-right: 0px;
}

.singleOrderLeftSide {
  background-color: #dfdfdf;
  flex: 2;
  display: flex;
  // justify-content: space-between;
  gap: 100px;
  padding: 30px 60px 50px 30px;
  height: auto;

}

.singleOrderRightSide {
  flex: 1;
  background-color: #dfdfdf;
  height: auto;
  padding: 50px;
}

.ant-table,
.ant-table-bordered {
  border-radius: 0 !important;
}


.ant-pagination-item-link,
.ant-pagination-next {
  background: none !important;
  border: none !important;
}

.left-row {
  display: flex;
  margin-bottom: 15px;
  justify-content: space-between;

  :nth-child(1) {
    width: 140px;
  }
}

.row-span {
  font-size: 14px !important;
  font-family: sans-serif !important;
  color: #4d4d4d;
  line-height: 22px !important;
  display: flex;
  flex: 1;
}

.orangeSpan {
  color: #f84804 !important;
  font-weight: 700;
  font-size: 14px !important;
}

.ant-input {
  border: none !important;

  &:hover,
  &:focus {
    border-color: #40a9ff !important;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2) !important;
    border-right-width: 1px !important;
    outline: 0 !important;
  }
}

.readonly {
  border-radius: 4px !important;
  width: 100% !important;
  padding: 4px 4px 4px 5px !important;
}

.info-top {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.info-bottom {
  text-align: center;

  >div {
    display: flex;
    flex-direction: column;
    gap: 20px !important;
  }
}

.input-readonly {
  border-bottom: 1px solid #c2c2c2 !important;
  border-radius: 4px;
  padding-left: 5px !important;
}

.reference {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.ind-row {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #ccc;
  padding: 6px 8px;
  margin-bottom: 5px;

  >span {
    color: #4d4d4d;
    font-size: 14px !important;
    font-family: sans-serif;
  }
}

.search-input {
  padding-left: 6px !important;
  padding-right: 34px !important;
}

.padding {
  padding-left: 6px !important;
}

@media screen and (min-width:500px) and (max-width:1240px) {
  .orderCol {
    display: flex;
    flex-wrap: wrap
  }

  .singleOrderLeftSide {
    gap: 70px;
    padding-right: 30px;
  }

  .orderHeader {
    margin-top: 35px;
  }
}