.ant-layout-sider .ant-menu-submenu-title span i {
  top: 2px;
  margin-right: 10px;
}
.ant-layout-sider .ant-menu-submenu-title span i::before {
  font-size: 22px;
}

.top-bar-menu-container .top-bar-menu-item-box i {
  margin-right: 10px;
}

.top-bar-menu-container .top-bar-menu-item-box i::before {
  font-size: 22px;
}

.navMenuIcon {
  margin-right: 5px;
}
