th,
td {
  font-size: 0.875em;
}

th {
  text-align: center;
}

.product-table::-webkit-scrollbar {
  width: 2px;
}

.product-table::-webkit-scrollbar-track {
  background: #f0f0f0;
}

.product-table::-webkit-scrollbar-thumb {
  background: #fb5700;
}

.table {
  .product-table {
    // max-height: 475px;
    // overflow-y: scroll !important;
    // padding-right: 50px;
    padding-bottom: 50px;
    // margin-bottom: 100px;
    width: 100%;
    max-width: 1250px;
  }
}

.table>.product-table>div>div>div>div>div>div>div>table>thead>tr>th {
  border-bottom: 2px solid black;
  text-align: center;
}


.table {
  width: 100%;
  //max-width: 1250px;
  border-collapse: collapse;
  border-spacing: 0;
  border-radius: 2px;
  text-transform: uppercase;
  margin-bottom: 0px !important;
  // max-height: 450px;
  // overflow-y: scroll !important;
  // padding-right: 50px;

  >thead>tr>th,
  >tbody>tr>th,
  >tfoot>tr>th,
  >thead>tr>td,
  >tbody>tr>td,
  >tfoot>tr>td {
    // padding: 13px 15px;
    padding: 0;
    line-height: 1.42857143;
    vertical-align: top;
    border-top: 1px solid #ffffff;
  }

  >thead>tr>th {
    vertical-align: bottom;
    border-bottom: 2px solid #ffffff;
    text-transform: uppercase;
  }

  >caption+thead>tr:first-child>th,
  >colgroup+thead>tr:first-child>th,
  >thead:first-child>tr:first-child>th,
  >caption+thead>tr:first-child>td,
  >colgroup+thead>tr:first-child>td,
  >thead:first-child>tr:first-child>td {
    border-top: 0;
  }

  >tbody {
    +tbody {
      border-top: 2px solid #ffffff;
    }

    tr {
      &:hover {
        >td {
          background-color: #f0f6f8;
        }
      }
    }
  }
}

.order-container {
  max-width: 1150px;
}

.delivery-container {
  padding-bottom: 30px;
}

@media screen and (max-width:1620px) {
  .delivery-container {
    max-width: 1072px;
  }
}

@media screen and (min-width:1620px) and (max-width:1880px) {
  .delivery-container {
    max-width: 1074px;
  }
}

@media screen and (min-width:1880px) {
  .delivery-container {
    max-width: 1074px;
  }
}

.table>thead>tr:first-child {
  // border-top-right-radius: 2px;
  // border-top-left-radius: 2px;
}

.table>thead>tr:first-child>th:first-child {
  border-top-left-radius: 2px;
}

.table>thead>tr:first-child>th:last-child {
  border-top-right-radius: 2px;
}

.table>tfoot>tr:last-child {
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
}

.table>tfoot>tr:last-child>th:first-child {
  border-bottom-left-radius: 2px;
}

.table>tfoot>tr:last-child>th:last-child {
  border-bottom-right-radius: 2px;
}

.table>tbody>tr:last-child>th:first-child,
.table>tbody>tr:last-child>td:first-child {
  border-bottom-left-radius: 2px;
}

.table>tbody>tr:last-child>th:last-child,
.table>tbody>tr:last-child>td:last-child {
  border-bottom-right-radius: 2px;
}

.table>tfoot+tbody>tr:last-child>th:first-child,
.table>tfoot+tbody>tr:last-child>td:first-child {
  border-bottom-left-radius: 0;
}

.table>tfoot+tbody>tr:last-child>th:last-child,
.table>tfoot+tbody>tr:last-child>td:last-child {
  border-bottom-right-radius: 0;
}

.table-condensed>thead>tr>th,
.table-condensed>tbody>tr>th,
.table-condensed>tfoot>tr>th,
.table-condensed>thead>tr>td,
.table-condensed>tbody>tr>td,
.table-condensed>tfoot>tr>td {
  padding: 5px;
}

.table-bordered {
  border: 1px solid #ffffff;

  >thead>tr>th,
  >tbody>tr>th,
  >tfoot>tr>th,
  >thead>tr>td,
  >tbody>tr>td,
  >tfoot>tr>td {
    border: 1px solid #ffffff;
  }

  >thead>tr>th,
  >thead>tr>td {
    border-bottom-width: 2px;
  }
}

.table-striped>tbody>tr:nth-of-type(odd) {
  background-color: #fcfcfd;
}

.table-hover>tbody>tr:hover {
  background-color: #e7e9ee;
}

.table-responsive {
  overflow-x: auto;
  overflow-y: hidden;
  min-height: 0.01%;

  @include media('<md') {
    width: 100%;
    margin-bottom: 12.75px;
    overflow-y: hidden;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    border: 1px solid #ffffff;
  }

  th,
  td {
    @include media('<md') {
      white-space: nowrap;
    }
  }

  >.table-bordered {
    @include media('<md') {
      border: 0;

      th {
        &:first-child {
          border-left: 0;
        }

        &:last-child {
          border-right: 0;
        }
      }
    }
  }
}

/* colors */
.table>.success>tr>th,
.table>.success>tr>td {
  background-color: #505b72;
  color: #fff;
  border-color: rgba(255, 255, 255, 0.25);
}

.table>.primary>tr>th,
.table>.primary>tr>td {
  background-color: #2574ab;
  color: #fff;
  border-color: rgba(255, 255, 255, 0.25);
}

.table>.success-2>tr>th,
.table>.success-2>tr>td {
  background-color: #4f66a0;
  color: #fff;
  border-color: rgba(255, 255, 255, 0.25);
}

.table>.danger>tr>th,
.table>.danger>tr>td {
  background-color: #d9534f;
  color: #fff;
  border-color: rgba(255, 255, 255, 0.25);
}

.table>.success>tr:first-child {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}

.table>.success>tr:first-child>th:first-child,
.table>.success>tr:first-child>td:first-child {
  border-top-left-radius: 0;
}

.table>.success>tr:first-child>th:last-child,
.table>.success>tr:first-child>td:last-child {
  border-top-right-radius: 0;
}

.table-actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 15px;

  a {
    display: inline-flex;
    width: 20px;
    height: 16px;
    line-height: 16px;
    text-align: center;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  i {
    vertical-align: baseline;
  }
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  //color: #4f66a0;
  color: #f95e00;
  font-size: 14px;
  text-transform: uppercase;
}

.ant-tabs-tab:hover {
  color: #4f66a0;
}

.ant-tabs-tab-btn:focus,
.ant-tabs-tab-remove:focus,
.ant-tabs-tab-btn:active,
.ant-tabs-tab-remove:active {
  color: #4f66a0;
}

.ant-tabs-ink-bar {
  background: #f95e00;
}

.ant-table-row-expand-icon:focus,
.ant-table-row-expand-icon:hover {
  color: #4f66a0;
}

.ant-table-tbody .ant-table-row {
  background-color: whitesmoke !important;
}

.ant-table-container {
  border: none !important;
}

.ant-picker:hover,
.ant-picker-focused {
  border-color: #4f66a0;
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner,
.ant-picker-range .ant-picker-active-bar {
  background: #4f66a0;
}

td.ant-table-column-sort {
  background: initial;
  text-align: center;
}

.ant-table-tbody tr>.ant-table-cell {
  text-align: center;
}

.tableContainer .ant-table-tbody tr>.ant-table-cell:nth-child(1),
.tableContainer .ant-table-tbody tr>.ant-table-cell:nth-child(2) {
  text-align: left !important;
}

.tableContainer .ant-table-tbody tr>.ant-table-cell:nth-child(2) {
  text-align: left !important;
}

.ant-table-tbody tr>.ant-table-cell:nth-child(1),
.ant-table-tbody tr>.ant-table-cell:nth-child(2) {
  text-align: center;
}

.actions-block {
  background-color: $wht !important;
  min-height: 80px;
  border-radius: $table-border-radius-top;
  //padding-top: 10px ;
  display: flex;
  align-items: center;
}

.table {
  border-radius: $table-border-radius;
  // box-shadow: $table-box-shadow;
  overflow-x: hidden !important;
  // .actions-block {
  //   margin-bottom: 8px;
  // }
}

.ant-table-filter-dropdown {
  padding: 10px;
  border-radius: 4px;

  .ant-btn {
    @include smallbtn;
    width: $small-btn-width !important;
  }
}

th.ant-table-cell {
  text-align: center !important;
}

.lock .icon-unlock {
  display: inline;
}

.lock:hover .icon-unlock,
.lock .icon-lock {
  display: none;
  opacity: 0.8 !important;
  color: #000 !important;
}

.lock:hover .icon-lock {
  display: inline;
}

.lock:hover svg {
  // opacity: 0.5 !important;
  // color: transparent !important;
}

.ordered td {
  color: #f85d00;
  font-weight: 800;

  div {
    button {
      .ant-btn {
        .ant-btn-icon-only {
          color: '#f85d00' !important;
        }
      }
    }
  }
}

.ordered .columnCompany {
  margin-left: 20px;
  margin-top: 60px;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 8px;

  >span {
    margin-top: 10px;
  }

  span {
    width: 100%;
    display: block;
    padding: 0 8px;
  }

  .headerzaisporuku {
    background: $primary-color;
    padding-top: 22px;
    padding-bottom: 22px;
    border-radius: 10px 10px 0px 0px;
    color: #fff;
    font-size: 13px;
    font-weight: bold;
  }

  .ant-select-clear {
    top: 40%;
    width: 7%;
    background: linear-gradient(180deg, #2768e9, #0b32c0f6);

    span {
      color: #fff;
    }
  }

  .ant-row {
    .ant-col {
      padding: 0 8px;
    }
  }

  .ant-select {
    width: 100%;

    //color: #fff;
    .ant-select-selector {
      // background: linear-gradient(180deg, #3372ed, #1138c2) !important;
      margin-top: 5px;
      margin-bottom: -4px;

      .ant-select-selection-placeholder {
        color: #000;
      }
    }

    .ant-select-arrow {
      color: #000;
      top: 60%;
      left: 45%;
    }
  }

  // display: flex;
  // justify-content: center;
}

.ant-select .ant-select-single .ant-select-allow-clear .ant-select-show-arrow {
  width: 100%;
  background: linear-gradient(180deg, #3372ed, #1138c2) !important;
  color: #fff;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  // border-radius: 10px 10px 0px 0px;
}

.ant-table-bordered .ant-table-thead>tr>th .ant-table-filter-column .ant-table-column-title {
  color: #7D7D7D;
  text-align: left;
}

.product-table div .ant-table-wrapper .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table.container .ant-table-content .table-layout .ant-table-thead>tr>th {
  border: 1px solid red;
}

.ant-table-bordered .ant-table-thead>tr>th {
  background: whitesmoke;
  color: #7D7D7D;
  font-weight: 800;
  border-bottom: 1px solid rgb(17, 17, 17);
}

.ant-table {
  table {
    tbody>tr {
      background-color: whitesmoke;
    }

    tbody>tr:nth-child(2n + 1) {
      background-color: #f0f0f0;
    }
  }

  .ant-table-tbody>tr>td,
  .ant-table-thead>tr>th {
    padding: 4px !important;
  }
}

.table {
  .order-title-container {
    padding-top: 50px;
    margin-bottom: 20px;
    text-align: left;
    font-weight: 800;
    color: black;
    display: flex;
    align-items: center;
    font-size: 16px;

    .order-title-text {
      margin-bottom: 0;
    }
  }
}

.order-title-text {
  font-size: 14px;
}

.user-table {

  .ant-table {
    border-top: unset !important;

    .ant-table-tbody>tr {
      font-weight: 500;
      color: gray;

      &:hover {
        color: rgb(254, 96, 44);
      }

      >td {
        padding: 3px 3px !important;
        text-align: left;

        >button {
          text-transform: uppercase;
        }
      }
    }
  }

  .ant-table-cell-row-hover {
    background: lighten($color: gray, $amount: 40) !important;
  }

  .ant-table-thead>tr>th {
    background-color: whitesmoke;
    text-align: left !important;
    padding: 3px !important;
    color: #444;
    font-size: 12px !important;
    font-weight: bold !important;
    font-family: '-apple-system', 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Helvetica Neue', 'Arial',
      'Noto Sans', 'sans-serif', 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji' !important;
    border: unset !important;
    border-top: unset !important;
    border-bottom: 2px solid #888 !important;

    &.ant-table-column-has-actions {
      &.ant-table-column-has-sorters:hover {
        background-clip: padding-box;
        background-color: darken($c-primary, 3);
      }

      &.ant-table-column-has-filters {
        &:hover {

          .ant-table-filter-icon:hover,
          .anticon-filter:hover {
            background-color: darken($c-primary, 3);
            color: #fff;
          }
        }

        .anticon-filter {
          &.ant-table-filter-open {
            background-color: darken($c-primary, 3);
            color: #fff;
          }
        }

        .ant-table-filter-icon.ant-table-filter-open {
          background-color: darken($c-primary, 3);
          color: #fff;
        }
      }
    }

    &.ant-table-column-sort {
      background-color: darken($c-primary, 3);
    }

    .ant-table-column-sorter {
      color: #7D7D7D;

      &-down.on,
      &-up.on {
        color: yellow;
      }
    }

    .ant-table-filter-selected.anticon-filter {
      color: yellow;
    }
  }

  .ant-table-cell::before {
    display: none;
  }

  .ant-table-thead>tr>:last-child {
    width: 10%;
  }
}

.delivererTable tr td {
  line-height: 1.4;
  font-weight: 400;
  font-size: 16px;
  text-transform: initial;
}

.spanRabat {
  background: whitesmoke;
  padding: 12px 0px 8px 0px;
  color: #7D7D7D;
  font-size: 13px;
  font-family: '-apple-system',
    'BlinkMacSystemFont',
    'Segoe UI',
    'Roboto',
    'Helvetica Neue',
    'Arial',
    'Noto Sans',
    'sans-serif',
    'Apple Color Emoji',
    'Segoe UI Emoji',
    'Segoe UI Symbol',
    'Noto Color Emoji' !important;
  font-weight: 800;
  margin-top: -7px;
}

.categoryRabat {
  color: #3d3d3d;
  font-size: 15px;
  margin-bottom: 10px;
  font-family: '-apple-system',
    'BlinkMacSystemFont',
    'Segoe UI',
    'Roboto',
    'Helvetica Neue',
    'Arial',
    'Noto Sans',
    'sans-serif',
    'Apple Color Emoji',
    'Segoe UI Emoji',
    'Segoe UI Symbol',
    'Noto Color Emoji' !important;
  font-weight: 600;
  margin: 40px 0px 5px;
}

.rabatLine {
  //position: relative;
  //width: 80%;
  //left: 22%;
  position: absolute;
  width: 50%;
  left: 2%;
  height: 2px;
  background-color: gray;
  top: 27.5px;
  height: 2px;
  background-color: gray;
}

@media screen and (min-width:1250px) {
  .spanRabat {
    font-size: 12px;
    margin-top: -7.5px;
    transition: all .3s ease;
  }

  .rabatLine {
    top: 26.6px !important;
  }
}

@media screen and (min-width:1078px) and (max-width:1250px) {
  .spanRabat {
    font-size: 10px;
    margin-top: -5px;
    transition: all .3s ease;
  }

  .rabatLine {
    top: 26.6px !important;
  }
}

@media screen and (min-width:992px) and (max-width:1078px) {
  // .spanRabat {
  //   font-size: 10px;
  //   margin-top: 4px
  // }

  .rabatLine {
    top: 38.6px;
  }
}

@media screen and (max-width:992px) {
  // .spanRabat {
  //   font-size: 10px;
  //   margin-top: 4px;
  //   padding: 5.5px !important;
  // }

  .rabatLine {
    top: 39.5px;
  }
}

.divRabat {
  border-bottom: 2px solid gray;
}

@media (max-width:1005px) {
  // .spanRabat {
  //   padding: 13.7px !important;
  // }
}

@media screen and (min-width:1005px) and (max-width:1078px) {
  // .spanRabat {
  //   padding: 5.5px !important;
  // }
}

.rabatLine {
  display: none !important;
}

.ant-table-column-sorters .ant-table-column-sorter-full {
  color: gray !important;
}

.anticon-plus,
.anticon-minus {
  color: gray;
}

.orderProductTable .ant-table-cell:nth-child(3) {
  text-align: left !important;
}

.redTextRabat {
  color: red !important;
}

.additionalDiscount {
  color: #f85d00 !important;
  font-weight: 800;
}

.secondSection {
  background-color: #ffffff;
  width: 100%;
  box-sizing: border-box;
  padding-left: 40px;
  padding-right: 30px;
}

.custom-ordered-products-table .ant-table-tbody>tr>td {
  padding: 0px !important;
}

.orderProductTable .ant-table-cell,
.orderProductTable .ant-table-thead th.ant-table-cell {
  background-color: #ffffff;
  margin-right: auto;
  margin-left: auto;
  margin-right: -10%;
  border: none;
  border-bottom: 2px solid gray;
}

.orderProductTable {
  border: none;
  padding-bottom: 0px !important;
  //margin-bottom: 50px;
  border-bottom: 2px solid gray;
}

.content-space {
  margin-right: 0px !important;
  margin-left: 0px !important;
}

.ant-table-content table .ant-table-tbody .ant-table-row .ant-table-cell {
  border: none !important;
}

.ant-table-container table .ant-table-thead tr .ant-table-cell {
  border-bottom: 2px solid gray;
  border-top: none !important;
  border-radius: 0px;
}

.back-to-top-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  font-size: 18px;
  width: 30px;
  height: 30px;
  border: none;
  color: white;
  cursor: pointer;
  z-index: 9999;
}

.noProduction {
  color: #ffcccc;
}

@media (min-width:1240px) {
  .layout-container {
    display: none;
  }
}

.documentations {
  display: flex;
  align-items: center;
  margin-bottom: 25px;

  & img {
    width: 15px !important;
    height: 15px !important;
    margin-right: 2px;
  }

  & a {
    margin-left: 10px;
  }
}