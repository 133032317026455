.modal::before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  filter: blur(1px);
  z-index: 9;
}

.modal.open::before {
  opacity: 1;
}

.modal-content {
  position: fixed;
  top: 30%;
  left: 20%;
  background-color: #ffffff;
  padding: 30px 40px;
  border-radius: 4px;
  color: #000000d9;
  z-index: 11;
}

.close {
  color: #ffffff;
  float: right;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
  position: absolute;
  top: 0px;
  right: 0px;
  background-color: #f95e01;
  padding: 10px 15px;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

ul {
  line-height: 1.5;
}

li {
  line-height: 1.5;
}